<template>
  <svg class="d-flex my-5 mx-auto" :width="width" :height="height" viewBox="0 0 307 139" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-1.19249e-08 1 1 1.19249e-08 81.2542 124.136)" fill="#DEDEDE" ref="A1" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-1.19249e-08 1 1 1.19249e-08 71.0972 124.136)" fill="#DEDEDE" ref="A2" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-1.19249e-08 1 1 1.19249e-08 60.9404 124.136)" fill="#DEDEDE" ref="A3" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-1.19249e-08 1 1 1.19249e-08 50.7837 124.136)" fill="#DEDEDE" ref="A4" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-1.19249e-08 1 1 1.19249e-08 40.6272 124.136)" fill="#DEDEDE" ref="A5" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-1.19249e-08 1 1 1.19249e-08 30.4702 124.136)" fill="#DEDEDE" ref="A6" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-1.19249e-08 1 1 1.19249e-08 20.3135 124.136)" fill="#DEDEDE" ref="A7a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-1.19249e-08 1 1 1.19249e-08 20.3135 114.932)" fill="#DEDEDE" ref="A7b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-1.19249e-08 1 1 1.19249e-08 10.157 124.136)" fill="#DEDEDE" ref="A8a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-1.19249e-08 1 1 1.19249e-08 10.157 114.932)" fill="#DEDEDE" ref="A8b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-1.19249e-08 1 1 1.19249e-08 0 124.136)" fill="#DEDEDE" ref="A9a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-1.21645e-08 1 1 1.169e-08 0 114.932)" fill="#DEDEDE" ref="A9b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.195549 0.980694 0.980694 0.195549 84.0527 112.688)" fill="#DEDEDE" ref="B1" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.195549 0.980694 0.980694 0.195549 74.092 110.702)" fill="#DEDEDE" ref="B2" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.195549 0.980694 0.980694 0.195549 64.1313 108.716)" fill="#DEDEDE" ref="B3" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.195549 0.980694 0.980694 0.195549 54.1707 106.73)" fill="#DEDEDE" ref="B4" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.195549 0.980694 0.980694 0.195549 44.2102 104.744)" fill="#DEDEDE" ref="B5" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.195549 0.980694 0.980694 0.195549 34.2495 102.758)" fill="#DEDEDE" ref="B6" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.195549 0.980694 0.980694 0.195549 24.2888 100.771)" fill="#DEDEDE" ref="B7a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.195549 0.980694 0.980694 0.195549 26.5105 92.2017)" fill="#DEDEDE" ref="B7b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.195549 0.980694 0.980694 0.195549 14.4497 98.5498)" fill="#DEDEDE" ref="B8a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.195549 0.980694 0.980694 0.195549 16.6714 89.98)" fill="#DEDEDE" ref="B8b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.258819 0.965926 0.965926 0.258819 3.6814 96.541)" fill="#DEDEDE" ref="B9a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.258819 0.965926 0.965926 0.258819 6.06372 87.6499)" fill="#DEDEDE" ref="B9b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.381471 0.924381 0.924381 0.381471 89.6753 101.36)" fill="#DEDEDE" ref="C1" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.381471 0.924381 0.924381 0.381471 80.2864 97.4858)" fill="#DEDEDE" ref="C2" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.381471 0.924381 0.924381 0.381471 70.8977 93.6113)" fill="#DEDEDE" ref="C3" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.381471 0.924381 0.924381 0.381471 61.509 89.7368)" fill="#DEDEDE" ref="C4" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.381471 0.924381 0.924381 0.381471 52.1206 85.8628)" fill="#DEDEDE" ref="C5" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.381471 0.924381 0.924381 0.381471 42.7317 81.9878)" fill="#DEDEDE" ref="C6" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.381471 0.924381 0.924381 0.381471 33.343 78.1133)" fill="#DEDEDE" ref="C7a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.381471 0.924381 0.924381 0.381471 37.4692 69.8608)" fill="#DEDEDE" ref="C7b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.381471 0.924381 0.924381 0.381471 24.7732 73.9873)" fill="#DEDEDE" ref="C8a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.381471 0.924381 0.924381 0.381471 28.8994 65.7349)" fill="#DEDEDE" ref="C8b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.491188 0.871054 0.871054 0.491188 15.6033 68.9141)" fill="#DEDEDE" ref="C9a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.491188 0.871054 0.871054 0.491188 20.1243 60.8965)" fill="#DEDEDE" ref="C9b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.566755 0.823886 0.823886 0.566755 96.4709 91.0688)" fill="#DEDEDE" ref="D1" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.566755 0.823886 0.823886 0.566755 88.103 85.3125)" fill="#DEDEDE" ref="D2" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.566755 0.823886 0.823886 0.566755 79.7351 79.5562)" fill="#DEDEDE" ref="D3" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.566755 0.823886 0.823886 0.566755 71.3669 73.7998)" fill="#DEDEDE" ref="D4" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.566755 0.823886 0.823886 0.566755 62.9993 68.043)" fill="#DEDEDE" ref="D5" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.566755 0.823886 0.823886 0.566755 54.6311 62.2871)" fill="#DEDEDE" ref="D6" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.566755 0.823886 0.823886 0.566755 46.2632 56.5303)" fill="#DEDEDE" ref="D7a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.566755 0.823886 0.823886 0.566755 51.9761 48.5957)" fill="#DEDEDE" ref="D7b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.566755 0.823886 0.823886 0.566755 38.3276 50.8174)" fill="#DEDEDE" ref="D8a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.566755 0.823886 0.823886 0.566755 44.0413 42.8823)" fill="#DEDEDE" ref="D8b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.635914 0.77176 0.77176 0.635914 30.5356 44.3037)" fill="#DEDEDE" ref="D9a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.635914 0.77176 0.77176 0.635914 36.3892 37.2002)" fill="#DEDEDE" ref="D9b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.716497 0.69759 0.69759 0.716497 104.46 82.1694)" fill="#DEDEDE" ref="E1" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.716497 0.69759 0.69759 0.716497 97.3745 74.8921)" fill="#DEDEDE" ref="E2" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.716497 0.69759 0.69759 0.716497 90.2891 67.6147)" fill="#DEDEDE" ref="E3" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.716497 0.69759 0.69759 0.716497 83.2039 60.3379)" fill="#DEDEDE" ref="E4" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.716497 0.69759 0.69759 0.716497 76.1187 53.0605)" fill="#DEDEDE" ref="E5" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.716497 0.69759 0.69759 0.716497 69.0334 45.7832)" fill="#DEDEDE" ref="E6" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.716497 0.69759 0.69759 0.716497 61.948 38.5059)" fill="#DEDEDE" ref="E7a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.716497 0.69759 0.69759 0.716497 68.479 32.7261)" fill="#DEDEDE" ref="E7b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.837746 0.54606 0.54606 0.837746 115.141 74.3564)" fill="#DEDEDE" ref="F1" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.837746 0.54606 0.54606 0.837746 109.594 65.8477)" fill="#DEDEDE" ref="F2" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.837746 0.54606 0.54606 0.837746 104.048 57.3389)" fill="#DEDEDE" ref="F3" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.837746 0.54606 0.54606 0.837746 98.502 48.8301)" fill="#DEDEDE" ref="F4" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.837746 0.54606 0.54606 0.837746 92.9558 40.3213)" fill="#DEDEDE" ref="F5" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.837746 0.54606 0.54606 0.837746 87.4097 31.8125)" fill="#DEDEDE" ref="F6" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.837746 0.54606 0.54606 0.837746 81.8635 23.3037)" fill="#DEDEDE" ref="F7a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.837746 0.54606 0.54606 0.837746 88.6099 18.7603)" fill="#DEDEDE" ref="F7b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.929563 0.368663 0.368663 0.929563 127.184 69.7944)" fill="#DEDEDE" ref="G1" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.929563 0.368663 0.368663 0.929563 123.44 60.353)" fill="#DEDEDE" ref="G2" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.929563 0.368663 0.368663 0.929563 119.696 50.9116)" fill="#DEDEDE" ref="G3" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.929563 0.368663 0.368663 0.929563 115.952 41.4702)" fill="#DEDEDE" ref="G4" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.929563 0.368663 0.368663 0.929563 112.207 32.0293)" fill="#DEDEDE" ref="G5" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.929563 0.368663 0.368663 0.929563 108.463 22.5879)" fill="#DEDEDE" ref="G6" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.929563 0.368663 0.368663 0.929563 104.718 13.1465)" fill="#DEDEDE" ref="G7a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.929563 0.368663 0.368663 0.929563 112.046 10.1904)" fill="#DEDEDE" ref="G7b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.982992 0.18365 0.18365 0.982992 139.383 66.9204)" fill="#DEDEDE" ref="H1" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.982992 0.18365 0.18365 0.982992 137.518 56.9365)" fill="#DEDEDE" ref="H2" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.982992 0.18365 0.18365 0.982992 135.653 46.9526)" fill="#DEDEDE" ref="H3" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.982992 0.18365 0.18365 0.982992 133.787 36.9688)" fill="#DEDEDE" ref="H4" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.982992 0.18365 0.18365 0.982992 131.922 26.9844)" fill="#DEDEDE" ref="H5" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.982992 0.18365 0.18365 0.982992 130.057 17.0005)" fill="#DEDEDE" ref="H6" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.982992 0.18365 0.18365 0.982992 128.192 7.0166)" fill="#DEDEDE" ref="H7a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.982992 0.18365 0.18365 0.982992 135.872 5.74707)" fill="#DEDEDE" ref="H7b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.979112 -0.203322 -0.203322 0.979112 158.615 68.4136)" fill="#DEDEDE" ref="I1" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.979112 -0.203322 -0.203322 0.979112 160.68 58.4692)" fill="#DEDEDE" ref="I2" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.979112 -0.203322 -0.203322 0.979112 162.745 48.5244)" fill="#DEDEDE" ref="I3" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.979112 -0.203322 -0.203322 0.979112 164.81 38.5801)" fill="#DEDEDE" ref="I4" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.979112 -0.203322 -0.203322 0.979112 166.875 28.6353)" fill="#DEDEDE" ref="I5" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.979112 -0.203322 -0.203322 0.979112 168.94 18.6909)" fill="#DEDEDE" ref="I6" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.979112 -0.203322 -0.203322 0.979112 171.005 8.74609)" fill="#DEDEDE" ref="I7a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.979112 -0.203322 -0.203322 0.979112 178.717 10.2114)" fill="#DEDEDE" ref="I7b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.921021 -0.389514 -0.389514 0.921021 170.999 72.0635)" fill="#DEDEDE" ref="J1" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.921021 -0.389514 -0.389514 0.921021 174.955 62.709)" fill="#DEDEDE" ref="J2" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.921021 -0.389514 -0.389514 0.921021 178.912 53.3545)" fill="#DEDEDE" ref="J3" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.921021 -0.389514 -0.389514 0.921021 182.868 44)" fill="#DEDEDE" ref="J4" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.921021 -0.389514 -0.389514 0.921021 186.824 34.6455)" fill="#DEDEDE" ref="J5" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.921021 -0.389514 -0.389514 0.921021 190.78 25.2905)" fill="#DEDEDE" ref="J6" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.921021 -0.389514 -0.389514 0.921021 194.736 15.9365)" fill="#DEDEDE" ref="J7a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.921021 -0.389514 -0.389514 0.921021 202.383 19.3281)" fill="#DEDEDE" ref="J7b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.827058 -0.562117 -0.562117 0.827058 183.515 78.1548)" fill="#DEDEDE" ref="K1" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.827058 -0.562117 -0.562117 0.827058 189.224 69.7549)" fill="#DEDEDE" ref="K2" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.827058 -0.562117 -0.562117 0.827058 194.934 61.3545)" fill="#DEDEDE" ref="K3" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.827058 -0.562117 -0.562117 0.827058 200.643 52.9541)" fill="#DEDEDE" ref="K4" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.827058 -0.562117 -0.562117 0.827058 206.352 44.5537)" fill="#DEDEDE" ref="K5" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.827058 -0.562117 -0.562117 0.827058 212.062 36.1538)" fill="#DEDEDE" ref="K6" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.827058 -0.562117 -0.562117 0.827058 217.771 27.7539)" fill="#DEDEDE" ref="K7a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.827058 -0.562117 -0.562117 0.827058 224.466 32.4854)" fill="#DEDEDE" ref="K7b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.713886 -0.700261 -0.700261 0.713886 193.699 86.0396)" fill="#DEDEDE" ref="L1" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.713886 -0.700261 -0.700261 0.713886 200.812 78.7886)" fill="#DEDEDE" ref="L2" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.713886 -0.700261 -0.700261 0.713886 207.924 71.5381)" fill="#DEDEDE" ref="L3" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.713886 -0.700261 -0.700261 0.713886 215.036 64.2871)" fill="#DEDEDE" ref="L4" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.713886 -0.700261 -0.700261 0.713886 222.149 57.0366)" fill="#DEDEDE" ref="L5" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.713886 -0.700261 -0.700261 0.713886 229.261 49.7856)" fill="#DEDEDE" ref="L6" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.713886 -0.700261 -0.700261 0.713886 236.374 42.5352)" fill="#DEDEDE" ref="L7a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.713886 -0.700261 -0.700261 0.713886 242.911 48.6587)" fill="#DEDEDE" ref="L7b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.573723 -0.819049 -0.819049 0.573723 201.44 95.5493)" fill="#DEDEDE" ref="M1" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.573723 -0.819049 -0.819049 0.573723 209.759 89.7222)" fill="#DEDEDE" ref="M2" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.573723 -0.819049 -0.819049 0.573723 218.078 83.895)" fill="#DEDEDE" ref="M3" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.573723 -0.819049 -0.819049 0.573723 226.397 78.0679)" fill="#DEDEDE" ref="M4" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.573723 -0.819049 -0.819049 0.573723 234.716 72.2402)" fill="#DEDEDE" ref="M5" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.573723 -0.819049 -0.819049 0.573723 243.035 66.4136)" fill="#DEDEDE" ref="M6" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.573723 -0.819049 -0.819049 0.573723 251.354 60.5859)" fill="#DEDEDE" ref="M7a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.573723 -0.819049 -0.819049 0.573723 255.727 66.2959)" fill="#DEDEDE" ref="M7b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.405545 -0.914075 -0.914075 0.405545 207.192 106.173)" fill="#DEDEDE" ref="N1" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.405545 -0.914075 -0.914075 0.405545 216.476 102.054)" fill="#DEDEDE" ref="N2" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.405545 -0.914075 -0.914075 0.405545 225.76 97.9351)" fill="#DEDEDE" ref="N3" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.405545 -0.914075 -0.914075 0.405545 235.044 93.8159)" fill="#DEDEDE" ref="N4" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.405545 -0.914075 -0.914075 0.405545 244.328 89.6968)" fill="#DEDEDE" ref="N5" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.405545 -0.914075 -0.914075 0.405545 253.612 85.5781)" fill="#DEDEDE" ref="N6" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.405545 -0.914075 -0.914075 0.405545 259.584 74.5171)" fill="#DEDEDE" ref="N7a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.405545 -0.914075 -0.914075 0.405545 262.896 81.459)" fill="#DEDEDE" ref="N7b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.405545 -0.914075 -0.914075 0.405545 269.106 68.1694)" fill="#DEDEDE" ref="N8a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.405545 -0.914075 -0.914075 0.405545 273.053 76.3804)" fill="#DEDEDE" ref="N8b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.405545 -0.914075 -0.914075 0.405545 279.263 63.4082)" fill="#DEDEDE" ref="N9a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.405545 -0.914075 -0.914075 0.405545 283.209 71.6196)" fill="#DEDEDE" ref="N9b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.405545 -0.914075 -0.914075 0.405545 288.785 59.5991)" fill="#DEDEDE" ref="N10a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.405545 -0.914075 -0.914075 0.405545 292.731 67.8101)" fill="#DEDEDE" ref="N10b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.223718 -0.974654 -0.974654 0.223718 211.794 117.302)" fill="#DEDEDE" ref="O1" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.223718 -0.974654 -0.974654 0.223718 221.693 115.03)" fill="#DEDEDE" ref="O2" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.223718 -0.974654 -0.974654 0.223718 231.593 112.757)" fill="#DEDEDE" ref="O3" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.223718 -0.974654 -0.974654 0.223718 241.492 110.485)" fill="#DEDEDE" ref="O4" @click="changeSeat" />
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.223718 -0.974654 -0.974654 0.223718 251.392 108.213)" fill="#DEDEDE" ref="O5" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.223718 -0.974654 -0.974654 0.223718 261.291 105.94)" fill="#DEDEDE" ref="O6" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.223718 -0.974654 -0.974654 0.223718 271.19 103.668)" fill="#DEDEDE" ref="O7b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.223718 -0.974654 -0.974654 0.223718 281.347 100.812)" fill="#DEDEDE" ref="O8b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.223718 -0.974654 -0.974654 0.223718 291.504 98.2725)" fill="#DEDEDE" ref="O9b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.223718 -0.974654 -0.974654 0.223718 301.343 96.0508)" fill="#DEDEDE" ref="O10b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.223718 -0.974654 -0.974654 0.223718 269.032 96.6069)" fill="#DEDEDE" ref="O7a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.223718 -0.974654 -0.974654 0.223718 278.871 92.1636)" fill="#DEDEDE" ref="O8a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.223718 -0.974654 -0.974654 0.223718 289.028 89.624)" fill="#DEDEDE" ref="O9a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(-0.223718 -0.974654 -0.974654 0.223718 298.868 87.4023)" fill="#DEDEDE" ref="O10a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(9.93477e-08 -1 -1 -9.93477e-08 215.05 129.523)" fill="#DEDEDE" ref="P1" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(9.93477e-08 -1 -1 -9.93477e-08 225.207 129.523)" fill="#DEDEDE" ref="P2" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(9.93477e-08 -1 -1 -9.93477e-08 235.364 129.523)" fill="#DEDEDE" ref="P3" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(9.93477e-08 -1 -1 -9.93477e-08 245.52 129.523)" fill="#DEDEDE" ref="P4" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(9.93477e-08 -1 -1 -9.93477e-08 255.677 129.523)" fill="#DEDEDE" ref="P5" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(9.93477e-08 -1 -1 -9.93477e-08 265.834 129.523)" fill="#DEDEDE" ref="P6" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(9.93477e-08 -1 -1 -9.93477e-08 275.83 120.436)" fill="#DEDEDE" ref="P7a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(9.93477e-08 -1 -1 -9.93477e-08 275.83 129.612)" fill="#DEDEDE" ref="P7b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(9.93477e-08 -1 -1 -9.93477e-08 285.987 120.407)" fill="#DEDEDE" ref="P8a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(9.93477e-08 -1 -1 -9.93477e-08 285.987 129.612)" fill="#DEDEDE" ref="P8b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(9.93477e-08 -1 -1 -9.93477e-08 296.144 120.407)" fill="#DEDEDE" ref="P9a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(9.93477e-08 -1 -1 -9.93477e-08 296.144 129.612)" fill="#DEDEDE" ref="P9b" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(9.93477e-08 -1 -1 -9.93477e-08 306.3 120.407)" fill="#DEDEDE" ref="P10a" @click="changeSeat"/>
    <rect width="6.34796" height="6.34796" rx="3.17398" transform="matrix(9.93477e-08 -1 -1 -9.93477e-08 306.3 129.612)" fill="#DEDEDE" ref="P10b" @click="changeSeat"/>
  </svg>
</template>

<script>
export default {
  name: "Amphitheater",
  props:['selected','active', 'width', 'height'],
  mounted() {
    this.$refs[this.selected].classList.add('seatSelected')
    this.markSelectableTable()
  },
  methods:{
    markSelectableTable(){
      this.active.forEach( elem =>{
        if(this.selected !== elem)
          this.$refs[elem].classList.add('seatActive')
      })
    },
    changeSeat(event){
      let selectedSeat = Object.keys(this.$refs).find(key => this.$refs[key] === event.target)
      if(this.active.indexOf(selectedSeat) > 0){
        console.log(this.active.indexOf(selectedSeat))
        this.$emit('change', selectedSeat)
      }

    },

  },
  watch:{
      selected: function (newSelected, oldSelected){
        this.$refs[oldSelected].classList.remove('seatSelected')
        this.$refs[oldSelected].classList.add('seatActive')
        this.$refs[newSelected].classList.remove('seatActive')
        this.$refs[newSelected].classList.add('seatSelected')
      }
  },
}
</script>

<style>

.seatSelected{
  fill: #FF9900;
}
.seatActive{
  fill:  #959595;
  cursor: pointer;
}

</style>